const common = {
  return_to_homepage: "Return to Homepage",
  shop: "Shop",
  clever: "Clever",
  merchants: "Merchants",
  merchant: "Merchant",
  about: "About",
  blog: "Blog",
  contact: "Contact",
  say_hello: "Say Hello",
  instant_cashback: "instant cashback",
  google_play: "Google Play",
  app_store: "App Store",
  cashback: "cashback",
  cashback_money: "{{amount}} cashback",
  earn: "Earn",
  earn_money: "earn {{amount}}",
  show_more_deals: "Show more deals",
  view_all_blogs: "View all our blogs",
  save: "Save",
  as: "as",
  easy: "Easy",
  pay: "Pay",
  budgeting: "budgeting",
  hello_clever: "Hello Clever",
  funds: "funds",
  up_to: "Up to ",
  clever_address: "Level 3, 388 George St, Sydney NSW 2000",
  acknowledgement: "Acknowledgement",
  acknowledgement_section_1:
    "Hello Clever acknowledges Aboriginal and Torres Strait Islander peoples as the traditional owners of our lands throughout Australia. Our headquarters are located on the lands of the Gadigal people of the Eora Nation. We pay our respect to their culture and to Elders past, present and emerging.",
  acknowledgement_section_2:
    " Hello Clever services are provided by Hello Clever Pty Ltd (ABN 69 637 950 497). Copyright Hello Clever {{year}}. All rights reserved.",
  faqs: "FAQs",
  developer_portal: "Developer Portal",
  terms_and_conditions: "Terms and Conditions",
  privacy_policy: "Privacy Policy",
  our_partners: "Our Partners",
  careers: "Careers",
  contact_us: "Contact Us",
  blogs: "Blogs",
  apply: "apply",
  for_business: "For Business",
  industry: "Industry",
  e_commerce: "eCommerce",
  telco: "Telco",
  billers: "Billers",
  marketplace_and_fintech: "Marketplace and Fintech",
  smbs: "SMBs",
  links: "Links",
  business_faqs: "Business FAQs",
  get_in_touch: "Get in touch",
  get_started: "Get started",
  case_studies: "Case studies",
  solution: "Solutions",
  read_more: "Read more",
  subscribe: "Subscribe",
  to: "to",
  email_address: "Email Address",
  our_new_letters: "our newsletter",
  get_update:
    "Get spending updates, budgeting, tips, instant cashback deals and many more.",
  buy_to_earn: "Buy to earn",
  australian: "Australian",
  simple: "Simple",
  integration: "integration",
  ecosystem: "ecosystem",
  payid: "PayID",
  payto: "PayTo",
  xero: "Xero",
  card: "Card",
  for: "for",
  payment: "payment",
  processing: "processing",
  get: "Get",
  shop_now: "Shop Now",
  coming_soon: "Coming Soon",
  cash_flow: "cash flow",
  better: "Better",
  development: "development",
  team: "team",
  learn_more: "Learn more",
  launtel: "Launtel",
  find_out_more: "Find out more",
  related: "Related",
  articles: "articles",
  share: "Share",
  never: "never",
  send: "Send",
  business: "Business",
  sign_up: "Sign up",
  log_in: "Log in",
  at: "at",
  how_to_pay: "How to pay with",
  instruction_for: "Instruction for",
  how_it_works: "How it works?",
  search_bank: "Or search your bank",
  huge_thank:
    "A huge thank you to our customers, partners and investors for a great year. Read our 2022 review.",
  view_now: "View now",
  form_thanks: "Thanks for reaching out. Our team will contact you soon.",
  all_articles: "All Articles",
  get_started_now: "Get started now",
  speak_with_team: "Speak with our team",
  reward: "reward",
  real_time: "Real-time",
  in_store: "In-Store",
  resources: "Resources",
  merchant_support: "Merchant Support",
  consumer_support: "Consumer Support",
  t_and_c: "Terms & Conditions",
  payment_requests: "Payment Requests",
  online: "Online",
  in_store_payment: "In-Store Payments & Rewards",
  for_shoppers: "For Shoppers",
  clever_ai_demo: "Clever AI Demo",
  embedded_loyalty: "Embedded Loyalty",
  sydney_office: "Sydney Office",
  singapore_office: "Singapore Office",
  san_francisco_office: "San Francisco Office",
  tokyo_office: "Tokyo Office",
  singapore_clever_address: "120 Robinson Rd, #15-01, Singapore 068913",
  san_francisco_clever_address:
    "600 California St, San Francisco, CA 94108, USA",
  tokyo_clever_address: "3rd and 4th Floors, Stanford Annex Building,",
  tokyo_clever_address_2: "1-14-14 Tomigaya, Shibuya-ku, Tokyo 151-0063",
  unlock_exclusive:
    "Unlock exclusive deals, Instant Cashback offers, and possibly a secret handshake. (We’re still working on that last part.)",
};

export default common;
