const home = {
  it_pays_clever: "It Pays To Be Clever",
  all_in_one:
    "An AI-powered payment solution that seamlessly integrates Instant Cashback Rewards, Real-Time Payments, and Intelligent Insights. Unlock unbeatable growth by driving end-customer engagement and maximising conversions.",
  are_you_shopper: "Are you a shopper ?",
  see_how: "See how rewarding shopping can be.",
  download:
    "Make every purchase rewarding – download Hello Clever and experience money back instantly.",
  back: "back",
  we_work_with: "We work with",
  merchants_globally: "merchants globally",
  to_deliver_money: "to deliver money",
  forget_points:
    "Forget points! We’re here to enhance your customers' shopping experience with Instant Cashback, delivering real-time rewards that keep them coming back.",
  guaranteed: "Guaranteed",
  download_our_app: "Download our app to see all deals.",
  show_deals: " Show deals",
  get_paid_in: "Get Paid in",
  real_time: "Real-Time",
  with_global: "with global A2A",
  straight_through: "Straight-Through Reconciliation",
  all_payment: "All payment methods are validated and reconciled immediately.",
  low_code: "Low-Code Integration",
  integrate_our_payment:
    "Integrate our payment solutions into your business without writing a single line of code. Our user-friendly interface, AI-powered guides, and documentation help you set up and start processing in no time.",
  peace_of_mind: "Peace of Mind",
  power_by_rule_based:
    "Powered by rule-based, Machine Learning and AI systems, we ensure real- time verification, AML compliance, and robust fraud protection, guaranteeing that every transaction is safe and secure.",
  beyond: "Beyond transactions",
  we_go_beyond:
    "We go beyond processing real-time payments, to enhancing them, by bringing a noticeable lift to your conversion rates and sales with our Instant Cashback Rewards solution and AI-driven insights.",
  capture: "Capture more revenue and optimise costs",
  through_real_time:
    "Through real-time payment insights, automated processes, and AI-driven recommendations, we enable your business to streamline operations, reduce inefficiencies, and enhance customer retention. Unlock new growth opportunities while maintaining a lean, efficient approach to maximise your profitability.",
  go_global: "Go global from day one",
  expand_business:
    "Expand your business beyond borders with solutions designed to help you go global from day one. With seamless payment integrations, multi-currency support, and cross-border partnerships, we empower businesses to reach international markets effortlessly.",
  whether_you_are_shopping: "Whether you’re an online or in-store business,",
  we_make_it_simple: " we make it simple for your customers to earn",
  earn_cash_back: "cashback instantly",
  with: "with",
  decision_making: "decision making. No waiting days or months;",
  ai_powered: "AI-powered",
  rewards:
    "rewards are paid in seconds, powered by intelligent algorithms ensuring",
  get_paid: "seamless execution.",
  the_first_australian:
    "The first Australian buy-to-earn platform that makes shopping, payments, and money management easier for all. Trusted by 100,000+ Australians and {{amount}}+ merchants. Join us now!",
  are_you_merchant: "Are you a merchant? Discover our solution here",
  give_your_customers: "Give your customers",
  instant_cashback: "Instant cashback",
  at_checkout_with: "at checkout with",
  hello_clever: "Hello Clever",
  launch_with:
    "Launch with Hello Clever and connect with over 100,000 consumers, offering your customers instant cashback at checkout and driving loyalty like never before. Download the app and try it for yourself.",
  one_stop: "One-stop",
  fx: "FX",
  hello_clever_offer:
    "Hello Clever offers a comprehensive foreign exchange solution that simplifies international transactions. With competitive rates, seamless currency conversion, and multi-currency support, businesses can handle global payments efficiently from a single platform, reducing complexity and enhancing cost savings.",
  now_not_later: "Now, not later!",
  a_frictionless_checkout:
    "A frictionless checkout experience that earns you instant rewards from your favourite brands.",
  want_to_see_more: "Want to see more? We have",
  offers_in_our_app: "offers in our app.",
  you_spend: "you spend",
  withdraw_or_save_for_later:
    "From withdrawing to paying bills or saving for your goals, there are so many things you can do with your instant cashback!",
  no_more_jaw_dropping:
    "Easy budgeting: goes and sets a cap. Get notified in real-time when you exceed your limit. No more jaw-dropping surprises!",
  and_get_paid: "and get paid",
  just_one_click_away:
    "Pay mates, share a QR Code to get paid, send an invoice to collect payments for your business, just 1-click away.",
  refer_friends_to: "Refer friends to",
  what_people_say: "What’s new",
  backed_by_global: "Backed by global",
  venture_capital: "venture capital  ",
  the_more_the_merrier:
    "The more, the merrier. Earn {{amount}} for every friend who places their first order in the Hello Clever app. Your friends will earn {{amount}} too.",
  t_and_c_apply: "Terms & Conditions apply.",
  create_account: "Create an account in less than 2 minutes! 🚀 ",
  join_us_now: "Join us now",
  launch_faster: "Launch faster with our prebuilt integrations and",
  apis: "APIs",
  ready_to_get_started:
    "Ready to get started? Join hundreds of merchants who trust Hello Clever to elevate their payment experience. With AI-powered documentation, you can launch a conversion-focused and rewarding checkout experience quickly and effortlessly.",
  global_merchants: "Global Merchants.",
  pay_in: "PayIn",
  for: "for",
  accept_payments:
    "Accept payments with more flexibility and control. Boost conversion rates by offering the payment methods your customers know and trust at the checkout.",
  pay_out: "PayOut",
  leverage_our_global:
    "Leverage our global payout network with local settlement to accelerate payments, guarantee full amounts, and reduce transfer costs for global merchants.",
  coming_soon: "coming soon",
  ai_data_analysis: "AI as Your Data",
  analyst_to: "Analyst to",
  supercharge: "Supercharge",
  your_business: "Your Business",
  what_if: "What-if",
  analysis: "Analysis",
  forecast_your_sale:
    "Forecast your sales and maximise the impact of cashback campaigns with AI-driven predictions.",
  cashback_campaign_with_ai: "",
  elevate: "Elevate",
  performance: "Performance",
  use_our_recommendations:
    "Use our recommendations to automate and optimise decision-making and uncover growth opportunities in real-time.",
  actionable: "Actionable",
  insights: "Insights",
  extract_the_most_meaningful:
    "Extract the most meaningful, impactful insights from your data, market trends, and news, with AI recommending the best actions to boost your sales.",
  try_it_now: "Try It Now",
  get_started: "Get Started",
};

export default home;
