const merchant_api = {
  we_work_across: "We work across all",
  industries_from: "industries from B2B, B2C, and C2B",
  boost_your_sales:
    "Boost your sales, improve your cash flow, prevent fraud, and reduce chargebacks.",

  e_commerce_description:
    "Take advantage of the New Payment Platform that pre-authorises transactions to help businesses receive their payments almost instantly, making and receiving payments is quicker and easier than ever.",
  telco: "Telco",
  telco_description:
    "Let your customers top-up their balance, renew their subscriptions, manage auto top-ups fast and hassle free.",
  biller: "Biller Service",
  biller_description:
    "Bill your customers with a unique QR code and get pre-authorised payments set up in seconds.",
  marketplace_and_fintech: "Marketplace and Fintech",
  marketplace_and_fintech_description:
    "Increase customer lifetime value with instant deposits and instant payouts. Choose how to reward your customers as they use your services.",
  smb: "SMBs",
  sme_description:
    "Easily utilise Hello Clever's bank-to-bank payment solution to charge your customers, bypass high-cost card fees, and keep more of your hard earned cash.",
  we_have_partnered:
    "We have partnered with NPP and 70+ banks to bring you a secure product. Easily integrate your e-commerce marketplace via our straightforward and understandable API documentation.",
  do_much_more: "Do much more with",
  payment_api: "Payment API",
  one_off: "One-off payments with PayID",
  one_off_description:
    "Instant bill payments: You can generate a single use PayID for each transaction that enables real-time verification and settlement.\n\nCustomer PayID: You can assign each customer a one-off PayID so they can save it in their bank for future payments.",
  recurring: "Recurring payments with PayTo",
  recurring_description:
    "Fixed recurring: Subscriptions, repayments, loans, and more.\n\nVariable recurring: Amounts depending on the actual usage of your service.\n\nAd-hoc: Pre-authorised account to account payment at point of sales, via a paper or PDF invoice.",
  direct: "Direct debit replacement",
  direct_description:
    "Migrate from direct debit to real-time payments with PayTo.",
  case_study: "Case study",
  how_launtel:
    "Learn how Australian Telco company, Launtel, partnered  with HelloClever to provide a real-time payment solution, that makes internet billing faster and more efficient with PayTo.",
  discover_how:
    "Discover how Cake Creations by Kate, a Melbourne-based bakery specialising in custom cookies, cupcakes, cakes, macarons, and more, is revolutionising their business with Hello Clever’s PayID and real-time payment solutions.",
  cake_creations: "Cake Creations by Kate",
};

export default merchant_api;
