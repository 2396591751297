import { memo, useEffect, useState } from "react";

import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { NextLink } from "Components/Lib/Next";
import { Button } from "Components/Own";
import { NormalImage } from "Components/Lib";
import { PLATFORM } from "Constants/Platform.Constant";
import { APP_DOWNLOAD_URL } from "Constants/URL.Constant";
import { classnames } from "Utils/Classnames.Util";
import { checkOS } from "Utils/Common.Util";
import { localesText } from "Utils/LocalesText.Util";

import androidIcon from "Assets/Images/Icons/android.png";
import iosIcon from "Assets/Images/Icons/ios.png";

import androidBlueIcon from "Assets/Svg/android_blue.svg";
import iosBlueIcon from "Assets/Svg/ios_blue.svg";

import { Typography } from "../Typography";

const AppDownLoadButton = ({
  theme = "light",
  className,
  showSeperate = false,
  seperateButtonClassName,
  important = true,
}: {
  theme?: "dark" | "light" | "white" | "gradient";
  seperateButtonClassName?: string | undefined;
  className?: string | undefined;
  showSeperate?: boolean;
  important?: boolean;
}) => {
  const [currentOs, setCurrentOS] = useState<string | undefined>(undefined);
  const [isScroll, setIsScroll] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const os = checkOS();
    setCurrentOS(os);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY >= 0 && currentScrollY < 500) {
        setIsScroll(false);
      } else {
        setIsScroll(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const androidDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  const iosDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  if (!currentOs) return null;

  if (currentOs === PLATFORM.IOS) {
    return <IOSButton className={className} theme={theme} />;
  }

  if (currentOs === PLATFORM.ANDROID) {
    return <AndroidButton className={className} theme={theme} />;
  }

  if (showSeperate) {
    return (
      <div
        className={classnames(className, {
          "flex flex-wrap items-center justify-center gap-4": important,
        })}
      >
        <AndroidButton className={seperateButtonClassName} theme={theme} />
        <IOSButton className={seperateButtonClassName} theme={theme} />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        "min-h-12 flex h-12 items-center justify-evenly rounded-full ",
        {
          "border-2 border-white":
            theme === "light" || (theme === "gradient" && isScroll),
          "bg-black": theme === "dark",
          "bg-white border-clever-link border": theme === "white",
          "border-gradient-app-download bg-[radial-gradient(97.57%_210.75%_at_0.9%_2.98%,rgba(111,255,246,0.55)_0%,rgba(143,209,245,0.146)_76.56%,rgba(56,183,255,0.146)_95.31%)]":
            theme === "gradient" && !isScroll,
        },
        className,
      )}
    >
      <NextLink
        className="z-10"
        href={APP_DOWNLOAD_URL.ANDROID}
        target="_blank"
        onClick={androidDownload}
      >
        <NormalImage
          src={theme === "white" ? androidBlueIcon.src : androidIcon.src}
          alt="android"
          width={theme === "white" ? 24 : 21}
          height={theme === "white" ? 24 : 12}
        />
      </NextLink>

      <div
        className={classnames("mx-4 h-full w-[1px] bg-white", {
          "border-clever-link border opacity-100 mx-3": theme === "white",
        })}
      />
      <NextLink
        className="z-10"
        href={APP_DOWNLOAD_URL.IOS}
        target="_blank"
        onClick={iosDownload}
      >
        <NormalImage
          src={theme === "white" ? iosBlueIcon.src : iosIcon.src}
          alt="ios"
          width={theme === "white" ? 24 : 16}
          height={theme === "white" ? 24 : 20}
        />
      </NextLink>
    </div>
  );
};

const IOSButton = ({
  theme,
  className,
}: {
  className?: string | undefined;
  theme?: "dark" | "light" | "white" | "gradient";
}) => {
  const iosDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  return (
    <NextLink href={APP_DOWNLOAD_URL.IOS} target="_blank" onClick={iosDownload}>
      <Button
        className={classnames(
          "flex w-[166px] items-center justify-center gap-2",
          {
            "border-2 border-white": theme === "light",
            "bg-black": theme === "dark",
            "bg-clever-link": theme === "white",
          },
          className,
        )}
        shape="circle"
      >
        <NormalImage
          src={theme === "white" ? iosBlueIcon.src : iosIcon.src}
          alt="ios"
          width={theme === "white" ? 24 : 16}
          height={theme === "white" ? 24 : 20}
        />
        <Typography.Content shouldScale={false} className="text-white">
          {localesText("common.app_store")}
        </Typography.Content>
      </Button>
    </NextLink>
  );
};

const AndroidButton = ({
  theme,
  className,
}: {
  className?: string | undefined;
  theme?: "dark" | "light" | "white" | "gradient";
}) => {
  const androidDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  return (
    <NextLink
      href={APP_DOWNLOAD_URL.ANDROID}
      target="_blank"
      onClick={androidDownload}
    >
      <Button
        className={classnames(
          "flex w-[166px] items-center justify-center gap-2",
          {
            "border-2 border-white": theme === "light",
            "bg-black": theme === "dark",
            "bg-clever-link": theme === "white",
          },
          className,
        )}
        shape="circle"
      >
        <NormalImage
          src={theme === "white" ? androidBlueIcon.src : androidIcon.src}
          alt="android"
          width={theme === "white" ? 24 : 21}
          height={theme === "white" ? 24 : 12}
        />

        <Typography.Content shouldScale={false} className="text-white">
          {localesText("common.google_play")}
        </Typography.Content>
      </Button>
    </NextLink>
  );
};

export default memo(AppDownLoadButton);
