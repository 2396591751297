export const PATHS_NAME = {
  HOME: "/",
  ABOUT: "/about",
  BLOGS: "/blogs",
  BLOG_DETAIL: "/blogs/:blog_slug",
  SHOPPERS: "/for-shoppers",
  SHOPPER_DETAIL: "/shops/:shop_slug",
  MERCHANT_API: "/industries",
  GET_IN_TOUCH: "/get-in-touch",
  TERMS: "/terms",
  POLICY: "/policy",
  CASE_STUDIES: "/case-studies",
  CASE_STUDY_DETAIL: "/case-studies/:case_slug",
  BUY_TO_EARN: "/e-commerce",
  PAYTO: "/payto",
  XERO: "/xero",
  TYRO: "/tyro",
  IN_STORE: "/in-store",
  PAYMENT_REQUESTS: "/payment-requests",
  ONLINE: "/online",
  SHOPS: "/shops",
  FORECAST: "/demo-forecast/",
  INSIGHT: "/demo-overview-and-insights/",
  EMBEDDED_LOYALTY: "/embedded-loyalty",

  // Other links
  DASHBOARD: "https://merchant.helloclever.co/",
};
