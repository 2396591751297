const loyalty = {
  four_point: "Forget points.",
  start_rewarding: "Start rewarding your customers ",
  with_something: "with something they genuinely value:",
  instant_cashback_reward: "Instant Cashback Rewards.",
  drive_consumer_behaviour: "Drive Consumer Behaviour",
  achieve_your_goals:
    "Achieve your goals faster with Instant Cashback Rewards. Boost adoption of cost-effective payments, drive product sales, or motivate timely actions—all in real-time.",
  white_label: "White Label",
  it_your_business:
    "It’s your business and your brand, so why introduce a third party loyalty solution to your customer journey? Hello Clever’s Loyalty API ensures the experience is 100% yours, with no third party branding and no redirections.",
  funds_flow: "Funds flow",
  management: "management",
  through_our_campaign_dashboard:
    "Through our Campaign Dashboard, Hello Clever manages the flow of Cashback funds between your business and your customers, as defined by your campaign rules. Customer Cashback balances are shown in the customer profile of your app or website and can be withdrawn or redeemed as required.",
  loyalty: "Loyalty",
  programs: "Programs",
  add_new_dimension:
    "Add a new dimension to your loyalty program with the option of Instant Cashback Rewards. Give your customers the choice of points or cash or a mix of both. Simplify the Cashback redemption process and avoid the long delays typically experienced with card-matching Cashback schemes.",
  drive_new: "Drive New & Repeat Business with Embedded Loyalty",
  instant_cashback: "Instant Cashback",
  as_a_service: "as-a-service",
  hc_award:
    "Hello Clever’s award winning Instant Cashback Rewards is now available as-a-service for any app or website. So you can now embed your own custom branded Instant Cashback Rewards program through our simple API. Embedding Hello Clever's Instant Cashback Solution into your payment flow offers several key benefits:",
  instant_cashback_rewards:
    "Instant Cashback Rewards provide immediate gratification, leading to higher customer satisfaction and improved loyalty. Customers are more likely to return to businesses that offer tangible, real-time benefits rather than traditional points-based systems, which often lack appeal due to the delayed availability of the rewards.",
  enhanced_customer_loyalty: "Enhanced Customer Loyalty",
  increased_sales: "Increased Sales and Engagement",
  by_integrating:
    "By integrating Instant Cashback Rewards directly into your existing payment flows, your business can drive more sales. Shoppers are incentivised to spend more or make repeat purchases because they can immediately use their Instant Cashback for further transactions. This cycle of rewards and spending encourages continuous engagement.",
  differentiation_from_competitors: "Differentiation from Competitors",
  offering_instant_cashback:
    "Offering Instant Cashback through your business's own app creates a competitive edge in the market. In an era where many loyalty programs are complex or slow, businesses that provide instant, easy-to-understand rewards stand out and attract more customers.",
  cost_efficiency: "Cost Efficiency",
  with_hello_clever:
    "With Hello Clever's Loyalty API, your businesses can avoid the need to develop and maintain a complex rewards infrastructure. The integration is seamless and leverages Hello Clever's platform, reducing costs while providing a cutting-edge payment and rewards system.",
  real_time_campaign_insights: "Real-Time Campaign Insights",
  the_real_time:
    "The real-time nature of Hello Clever's platform allows businesses to gain valuable insights into customer spending behaviour and preferences. This data can be used to optimise marketing strategies and personalise rewards, further enhancing customer engagement.",
  seamlessly_embed:
    "Seamlessly embed Hello Clever’s Instant Cashback Rewards solution into your app or website.",
};

export default loyalty;
