import { localesText } from "Utils/LocalesText.Util";

import { PATHS_NAME } from "./PathName.Constant";
import {
  CAREERS,
  CONSUMER_SUPPORT,
  DEVELOPER_PORTAL,
  FAQS,
  MERCHANT_SUPPORT,
  OUR_PARTNERS,
} from "./URL.Constant";

export const MENUS = [
  {
    id: 1,
    label: "For Shoppers",
    url: "",
    hasSubmenu: true,
    isForShoppers: true,
  },
  {
    id: 2,
    label: localesText("common.for_business"),
    url: "",
    hasSubmenu: true,
    isForBusiness: true,
  },
  {
    id: 3,
    label: "Resources",
    url: "",
    hasSubmenu: true,
    isResources: true,
  },
  {
    id: 4,
    label: localesText("common.contact"),
    url: PATHS_NAME.GET_IN_TOUCH,
    hasSubmenu: false,
  },
];

export const SHOPPERS_SUBMENUS = {
  SOLUTION: {
    title: localesText("common.solution"),
    submenu: [
      {
        id: 1,
        label: localesText("common.online"),
        url: PATHS_NAME.SHOPPERS,
        target: undefined,
      },
      {
        id: 2,
        label: localesText("common.in_store"),
        url: PATHS_NAME.IN_STORE,
        target: undefined,
      },
      {
        id: 3,
        label: localesText("common.shop"),
        url: PATHS_NAME.SHOPS,
        target: undefined,
      },
    ],
  },
};

export const RESOURCES_SUBMENUS = {
  title: localesText("common.resources"),
  submenu: [
    {
      id: 1,
      label: localesText("common.merchant_support"),
      url: MERCHANT_SUPPORT,
      target: "_blank",
    },
    {
      id: 2,
      label: localesText("common.consumer_support"),
      url: CONSUMER_SUPPORT,
      target: "_blank",
    },
    {
      id: 3,
      label: localesText("common.about"),
      url: PATHS_NAME.ABOUT,
      target: undefined,
    },
    {
      id: 4,
      label: localesText("common.blog"),
      url: PATHS_NAME.BLOGS,
      target: undefined,
    },
    {
      id: 5,
      label: localesText("common.case_studies"),
      url: PATHS_NAME.CASE_STUDIES,
      target: undefined,
    },
    {
      id: 6,
      label: localesText("common.developer_portal"),
      url: DEVELOPER_PORTAL,
      target: "_blank",
    },
    {
      id: 7,
      label: localesText("common.clever_ai_demo"),
      url: PATHS_NAME.FORECAST,
      target: "_blank",
    },
  ],
};

export const BUSINESS_SUBMENUS = {
  INDUSTRY: {
    title: localesText("common.industry"),
    submenu: [
      {
        id: 1,
        label: localesText("common.e_commerce"),
        url: PATHS_NAME.BUY_TO_EARN,
        target: undefined,
      },
      {
        id: 2,
        label: localesText("common.telco"),
        url: `${PATHS_NAME.MERCHANT_API}#telco`,
      },
      {
        id: 3,
        label: localesText("common.billers"),
        url: `${PATHS_NAME.MERCHANT_API}#billers`,
      },
      {
        id: 4,
        label: localesText("common.marketplace_and_fintech"),
        url: `${PATHS_NAME.MERCHANT_API}#marketplace_and_fintech`,
      },
      {
        id: 5,
        label: localesText("common.smbs"),
        url: `${PATHS_NAME.MERCHANT_API}#smbs`,
      },
    ],
  },
  LINKS: {
    title: localesText("common.links"),
    submenu: [
      {
        id: 6,
        label: localesText("common.business_faqs"),
        url: FAQS,
        target: "_blank",
      },
      {
        id: 7,
        label: localesText("common.developer_portal"),
        url: DEVELOPER_PORTAL,
        target: "_blank",
      },
      {
        id: 8,
        label: localesText("common.case_studies"),
        url: PATHS_NAME.CASE_STUDIES,
        target: undefined,
      },
    ],
  },
  SOLUTION: {
    title: localesText("common.solution"),
    submenu: [
      {
        id: 9,
        label: localesText("common.payto"),
        url: PATHS_NAME.PAYTO,
        target: undefined,
      },
      {
        id: 10,
        label: localesText("common.xero"),
        url: PATHS_NAME.XERO,
        target: undefined,
      },
      {
        id: 11,
        label: localesText("common.in_store_payment"),
        url: PATHS_NAME.TYRO,
        target: undefined,
      },
      {
        id: 12,
        label: localesText("common.payment_requests"),
        url: PATHS_NAME.PAYMENT_REQUESTS,
        target: undefined,
      },
      {
        id: 13,
        label: localesText("common.embedded_loyalty"),
        url: PATHS_NAME.EMBEDDED_LOYALTY,
        target: undefined,
      },
    ],
  },
};

const INDUSTRY_URL = BUSINESS_SUBMENUS.INDUSTRY.submenu.map((menu) => menu.url);
const SOLUTION_URL = BUSINESS_SUBMENUS.SOLUTION.submenu.map((menu) => menu.url);
export const BUSINESS_URL = [...INDUSTRY_URL, ...SOLUTION_URL];

const SHOPPERS_SOLUTION_URL = SHOPPERS_SUBMENUS.SOLUTION.submenu.map(
  (menu) => menu.url,
);
export const SHOPPERS_URL = [
  ...SHOPPERS_SOLUTION_URL.filter((url) => url !== PATHS_NAME.HOME),
];

export const RESOURCES_URL = RESOURCES_SUBMENUS.submenu.map((menu) => menu.url);

export const FOOTER_MENUS = {
  LEFT: [
    {
      id: 1,
      name: localesText("common.merchant_support"),
      url: MERCHANT_SUPPORT,
      target: "_blank",
    },
    {
      id: 2,
      name: localesText("common.consumer_support"),
      url: CONSUMER_SUPPORT,
      target: "_blank",
    },
    {
      id: 3,
      name: localesText("common.developer_portal"),
      url: DEVELOPER_PORTAL,
      target: "_blank",
    },
    {
      id: 4,
      name: localesText("common.t_and_c"),
      url: PATHS_NAME.TERMS,
      target: undefined,
    },
    {
      id: 5,
      name: localesText("common.privacy_policy"),
      url: PATHS_NAME.POLICY,
      target: undefined,
    },
  ],
  RIGHT: [
    {
      id: 5,
      name: localesText("common.our_partners"),
      url: OUR_PARTNERS,
      target: "_blank",
    },
    {
      id: 6,
      name: localesText("common.careers"),
      url: CAREERS,
      target: "_blank",
    },
    {
      id: 7,
      name: localesText("common.contact_us"),
      url: PATHS_NAME.GET_IN_TOUCH,
    },
    {
      id: 8,
      name: localesText("common.blog"),
      url: PATHS_NAME.BLOGS,
      target: undefined,
    },
  ],
};
